<template>
    <v-container>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
                <v-card class="elevation-12">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>Login</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <v-form @submit.prevent="checkLogin" id="check-login-form">
                            <v-text-field
                                    :rules="modeloRules"
                                    name="formlogin"
                                    label="Login"
                                    type="text"
                                    v-model="formlogin"
                                    ></v-text-field>
                            <v-text-field
                                    name="formpassword"
                                    label="Password"
                                    id="password"
                                    type="password"
                                    v-model="formpassword"
                                    ></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-alert
                            :value="loginAlert"
                            type="error"
                            transition="scale-transition"
                            >Error no Login! Credenciais incorrectas.</v-alert>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                id="checkLogin"
                                class="v-btn-login"
                                @click="checkLogin"
                                color="primary"
                                type="submit"
                                form="check-login-form"
                                >Login</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    data () {
        return {
            modeloRules: [
                v => !!v || 'Campo requerido',
                v => /.+@.+/.test(v) || 'Campo debe ser válido'
            ],
            token: '',
            url: process.env.VUE_APP_API_URL,
            formlogin: '',
            formpassword: '',
            loginAlert: false
        }
    },
    methods: {
        checkLogin () {
            const goLogin = async () => {
                const config = {
                    headers: {
                        // eslint-disable-next-line
                        'accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
                const data = {
                    usuario: this.formlogin,
                    clave: this.formpassword
                }
                try {
                    return await this.$axios.post(
                        this.url + '/users/login',
                        data,
                        config)
                } catch (error) {}
            }

            const processLoginResult = async () => {
                const login_result = await goLogin()
                if (login_result) {
                    this.token = login_result.data.token
                    if (this.token !== undefined) {
                        this.$data.loginAlert = false
                        this.$store.dispatch('setLoggedIn')
                        this.$store.dispatch('setToken', this.token)
                        this.$store.dispatch('setUsername', this.formlogin)
                        this.$router.push('/menu')
                    } else {
                        this.$data.loginAlert = true
                    }
                } else {
                    this.$data.loginAlert = true
                }
            }
            processLoginResult()
        }
    }
}
</script>
